import Contact from "../components/conntact/contact"
import Allmedia from "../components/conntact/media"

const ContactPage = () => {
    return(
        <>  
        <div>
            <Contact />
            <Allmedia />
        </div>
        </>

    )

}

export default ContactPage