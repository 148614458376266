import Projects from "../components/projects/Projects"


const ProjectPage = () => {
    return (
        <>
        <div >
           <Projects />
        </div>
       
        </>
    )


}

export default ProjectPage